<template>
	<div>
		<el-drawer title="审批人" :visible.sync="dialog" custom-class="demo-drawer" ref="drawer" size="20%"
			@close="closeSelectAdmin"
			:close-on-click-modal="false" 
			:modal-append-to-body="false"
			:append-to-body="true">
			<el-form class="asset-form" label-position="top" ref="form" prop="" style="padding: 0px 25px;">
				<el-form-item label="">
					<el-radio v-model="form.type" :label="1">发起人自己</el-radio>
					<el-radio v-model="form.type" :label="2">指定成员</el-radio>
				</el-form-item>
				<el-form-item v-if="form.type==2" label="">
					<span style="color: #C0C4CC;font-size: 12px;">仅可指定加入到审批角色的人员</span>
					<el-select v-model="form.admin_id" placeholder="请选择" style="width: 300px;" @change="handleSelect">
						<el-option v-for="(item,index) in adminList" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				</el-row>
			</el-form>
		</el-drawer>

	</div>
</template>

<script>
	export default {
		props: ['processData'],
		data() {
			return {
				dialog: false,
				form: {
					type: 1,
					name:'',
					admin_id:''
				},
				adminList: [],
				processIndex: '',
				oldData:[]
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			getData(index,data) {
				this.processIndex = index
				this.oldData =  data
				this.$api.get('Admin/approvalRoleAdmin', null, res => {
					if (res.code == 200) {
						this.adminList = res.data.data
						this.dialog = true
					}
				})
			},
			handleSelect(val) {
				let opt = {}
				 opt = this.adminList.find((item)=>{
					return item.id  === val
				})
				this.form.name = opt.name
			},
			closeSelectAdmin(){
				
				this.oldData[this.processIndex] = {
					admin_id:'',
					content:"发起人自己审批",
					type:this.form.type
				}
				if(this.form.type == 2){
					this.oldData[this.processIndex] = {
						admin_id:this.form.admin_id,
						content:"指定成员 审批 "+this.form.name,
						type:this.form.type
					}		
				}
				console.log(this.oldData)
				this.$emit('update:processData',[])
				this.$nextTick(()=>{
					this.$emit('update:processData',this.oldData)
				})
				this.form  = {
					type: 1,
					name:'',
					admin_id:''
				}
			}
		},
	}
</script>
<style scoped>

</style>
