<template>
<div class="page-container">
	<p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	<div class="page-desc">
		<span v-if="type == 2">如何设置资产表单</span>
		<span v-if="type == 3">如何设置耗材表单</span>
		<span v-if="type == 4">如何设置员工申请表单</span>
	</div>  
	<div class="cate-box">
		<div class="table-box">
			<div style="margin-bottom:10px;">
			     <el-button size="small" type="" @click="handleAdd()">添加</el-button>
			 </div> 
			<el-table
			       :data="tableData" 
			       style="width:100%">
			        <el-table-column    
			           prop="name"
			           label="表单名称">
			       </el-table-column>
			       <el-table-column    
			           prop=""
			           label="审批流">
					   <template slot-scope="scope">
						   <el-switch
							:disabled="scope.row.name == '耗材盘点单'?true:false"
						     v-model="scope.row.status"
							 active-text="启用"
							 inactive-text="禁用"
							 :active-value="activeValue"
							 :inactive-value="inactiveValue"
							 @change="(val)=>changeSwitch(val,scope.row.id)"
						    >
						   </el-switch>
					   </template>
			       </el-table-column>
			     <el-table-column
			         prop="admin_name"
			         label="最后操作人">
			     </el-table-column>
				 <el-table-column
				     prop="update_time"
				     label="最后操作时间">
				 </el-table-column>
			       <el-table-column
			       fixed="right"
			       width="220px"
			       label="操作">
			       <template slot-scope="scope">
						<el-button   class="btn"  size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
						<!-- <el-button  class="btn"  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button> -->
			       </template>
			       </el-table-column>  
			</el-table>
			<AddFormVue ref="childDialog"></AddFormVue>
			<EditFormVue ref="childEditDialog"></EditFormVue>
		</div>
	</div>
</div>
</template>
<script>
import AddFormVue from './Add.vue'
import EditFormVue from './Edit.vue'
export default {
	// props:[
	// 	'formType'
	// ], 
	 inject:['reload'],	
	 components:{
	     AddFormVue,
		 EditFormVue
	 },
    data() {
        return {
			 type:2,		//1-卡片字段  2-资产表单  3-耗材表单  4-员工申请表单
			// type:this.formType,		
			 tableData:[],
			 activeValue:1,
			 inactiveValue:0,
			 total:0,
        }
    },
	watch:{
		$route:{
			immediate:true,
			handler(to,from){
				//  2-资产表单  3-耗材表单  4-员工申请表单
				this.type =  to.meta.form_type
				this.getData()
			}
	   } 	
	},
    created() {
		// this.getData()
    },
    mounted() {
		
    },
    methods:{
		getData(){			
			this.$api.post('AssetForm/index',{
				type:this.type
			},res=>{
				if(res.code == 200){
					this.tableData = res.data.list??[]
					this.total  = res.data.count					
				}
			})
		},
		
		handleAdd(id=0){
			this.$refs.childDialog.handleAdd(id,this.type)
		},
		handleEdit(id){
			this.$refs.childEditDialog.handleEdit(id)
		},
		
		changeSwitch(val,id){
			this.$api.put('AssetForm/updateStatus',{
				id:id,
				status:val
			},res=>{
				if(res.code == 200){
					this.$message.success(res.msg);			
				}else{
					this.$message.error(res.msg)
				}
			})
		}
		
		// handleDel(row){
		// 	this.$alert("确定删除该表单数据吗？", "删除提示", {
		// 	    showConfirmButton: true,
		// 	    showCancelButton: true,
		// 	    callback: action => {
		// 	        if (action == "confirm") {
		// 	            this.$api.delete("AssetForm/del", { "id": row.id }, res => {
		// 	                if (res.code == 200) {
		// 	                    this.$message.success(res.msg);
		// 	                    this.reload();
		// 	                }
		// 	            });
		// 	        }
		// 	        else if (action == "cancel") {
		// 	            this.reload();
		// 	        }
		// 	    }
		// 	});	
		// },

    },
}
</script>

<style scoped lang="scss">

</style>