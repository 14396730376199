<template>
	<div>
		<!-- 添加弹窗 -->
		<el-dialog :title="form.type==2?'添加资产表单':form.type ==3?'添加耗材表单':'添加员工申请表单'" :visible.sync='dialogAddVisible' width="50%" :close-on-click-modal="false">
			<el-form ref="form" :model="form" prop=""  label-width="100px">
				<el-form-item label="表单名称" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="审批状态" prop="status">
					<el-switch
					  v-model="form.status"
					  :active-value="activeValue"
					  :inactive-value="inactiveValue"
					  >
					</el-switch>
				</el-form-item>
			<!-- 	<el-form-item label="默认字段">
					<el-switch
					  v-model="form.default_status"
					  :active-value="activeValue"
					  :inactive-value="inactiveValue"
					  disabled 
					  >
					</el-switch>
				</el-form-item> -->
					<div v-for="(item,index) in form.items" style="border:1px dashed;padding:20px;">
					    <el-button size="small" icon="el-icon-delete" circle style="float:right;" @click="delItem(index)"></el-button>
						<el-form-item label="内容类型" prop="">
						    <el-radio-group v-model="item.type">
						        <el-radio  :label="0">单行文本</el-radio>
						        <el-radio  :label="1">多行文本</el-radio>
						        <el-radio  :label="2">下拉</el-radio>
						        <el-radio  :label="4">图片</el-radio>
						        <el-radio  :label="5">日期时间</el-radio>
						        <el-radio  :label="6">日期</el-radio>
						        <el-radio  :label="7">时间</el-radio>
								<el-radio  :label="11">金额</el-radio>
						    </el-radio-group>
						</el-form-item>
					    <div style="display:flex">
					        <label style="line-height:40px;margin-right:20px;">标题</label>
					        <el-input style="width:200px;margin-right:20px;" v-model="item.title" placeholder="" ></el-input>
							<label style="line-height:40px;margin-right:20px;">字段名</label>
							<el-input style="width:200px;margin-right:20px;" v-model="item.field_name" placeholder="" ></el-input>     
					        <label style="line-height:40px;margin-right:20px;">排序</label>
					        <el-input style="width:80px;" v-model="item.sort" placeholder="填写表单项排序" ></el-input>  
					        <label style="line-height:40px;margin:0px 20px;">必填</label>
					        <el-radio v-model="item.required_status" :label="0" style="line-height:40px;">否</el-radio>
					        <el-radio v-model="item.required_status" :label="1" style="line-height:40px;">是</el-radio>   
					    </div>
					</div>
					  <el-button type="" @click="addItems()" style="margin-top:20px;">添加</el-button>
	
				
			 </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirm()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	
	export default {
		inject: ['reload'],

		data() {
			return {
				dialogAddVisible: false,
				activeValue:1,
				inactiveValue:0,
				form: {
					id: '',
					name:'',
					status:1,
					type:2,
					items:[
						{
							title:'',
							type:0,
							sort:0,
							field_name:'',
							required_status:0,
							default_status:1,
						}
					]
				},
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			handleAdd(id, type) {
				this.form.id =  id
				this.form.type =  type
				if (id < 1) {
					this.$api.get('AssetForm/add', null, res => {
						if (res.code == 200) {
							this.dialogAddVisible = true
						}
					})
				} else {
					this.handleEdit(id)
				}
			},
			addItems(){   //追加
			    let data = {
			            title:'',
			            type:0,
			            sort:this.form.items.length,
						field_name:'',
			            required_status:0,
			        }
			    this.form.items.push(data)
			},
			handleEdit(id) { //编辑数据
				this.$api.get('AssetForm/edit', {
					'id': id
				}, res => {
					if (res.code == 200) {
						// this.form = res.data.info
						// this.dialogAddVisible = true
						// this.$nextTick(function(){
						// 	// this.form.pname =  'xxxxxxxx' 
						// 	this.$refs.tree.setCurrentKey(res.data.info.pid)
						// })
						
						this.form = res.data.info
						this.fieldData =  res.data.field_data
						this.groupData =  res.data.group_data
						this.dialogAddVisible = true
						this.$nextTick(()=>{ 
							this.$refs.tree.setCheckedNodes(res.data.info.checked_keys)
							this.checkboxGroup = res.data.default_checked
						})
					} else {  
						this.$message.error(res.msg)
					}
				})
			},
			confirm() {
				let url = 'AssetForm/add'
				if (this.form.id) {
					url = 'AssetForm/edit'
				}
		 	this.$api.post(url, this.form, res => {
					if (res.code == 200) {
						this.dialogAddVisible = false
						this.$message.success(res.msg)
						this.reload();
						this.$refs["form"].resetFields();
								
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			handleSelect(val){
				this.form.pid =  val
			},
			handleNodeClick(data) {
				console.log("click-tree:",data)
				this.form.parent_name = data.name
				this.form.pid = data.id
			},
			delItem(index){  //删除表单项
			    this.form.items.splice(index,1)
			},
		},
	}
</script>
<style lang="scss">
	.el-tree-node__expand-icon {
		font-size: 10px;
	}
</style>
