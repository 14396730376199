<template>
	<div>
		<el-dialog title="编辑表单" :visible.sync='dialogAddVisible' width="100%" class="asset-form-box"
			:close-on-click-modal="false" :modal-append-to-body="false" >
			<el-form :label-position="form.type!==4?'top':''" ref="form" :model="form" prop="" label-width="100px">
				<el-tabs v-model="activeName">
					<el-tab-pane label="基础设置" name="basic">
						<el-form-item label="表单名称" prop="name">
							<el-input v-model="form.name" disabled></el-input>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="表单设计" name="card">
						<div class="card-box" style="display: flex;">
							<div class="field-left">
								<!-- <el-button class="" size="small" @click="appendGroup">添加分组</el-button> -->
								<p style="font-weight: bold;position: relative;margin-bottom: 20px;font-size: 14px;">
									<span>可选字段</span>
									<span style="color: #409EFF;position: absolute;right: 5px;"
										@click="addField">添加可选字段</span>
								</p>
								<div class="field-list">
									<el-checkbox-group v-model="checkboxGroup" size="mini">
										<el-row>
											<!-- <div v-for="group in groupData"> -->
											<el-col :span="12" v-for="item in fieldData">
												<el-checkbox-button @change="handleField(item,$event)"
													style="width: 120px;margin-top: 3px;" :label="item.field_name">
													{{item.title}}
												</el-checkbox-button>
											</el-col>
											<!-- </div> -->
										</el-row>
									</el-checkbox-group>
								</div>
							</div>

							<el-row class="field-content">
								<!-- <div v-for="(group,index) in groupData"> -->
									<!-- <el-col :span="23" style="border-bottom:1px solid #E4E7ED;" v-if="group.group_name">
										<p @click="clickFormField(group,index)"
											style="font-weight: bold;font-size: 16px;border-bottom: 3px solid #409EFF;display: inline-block;">
											{{group.group_name}}
										</p>
										<i class="el-icon-delete" style="margin-left: 5px;" @click="delGroup(index)"></i>
									</el-col> -->
									<div  :style="form.type == 4?{width:'50%',margin:'0 auto'}:''">
										<div  v-for="(item,childIndex) in groupData" @click="clickFormField(item)">
											<el-col :span="form.type == 4?24:8" v-if="item.type !== 1 && item.type !== 4">
												<!-- 普通文本 --> 
												<el-form-item :label="item.title" prop="">
													<el-input v-if="item.type < 1" style="width: 280px;"
														v-model="item.value" placeholder="" disabled></el-input>
													<el-select v-if="item.type == 9" v-model="item.value" disabled
														placeholder="请选择" style="width: 280px;">
														<el-option label="" value="0">
														</el-option>
													</el-select>
													<el-date-picker style="width: 280px;" v-if="item.type == 6 || item.type == 5" disabled
														v-model="item.value" type="date" placeholder="选择日期">
													</el-date-picker>
													<i v-if="item.default_status != 1"  class="el-icon-delete" style="margin-left: 5px;" @click="delGroup(index,childIndex)"></i>
												</el-form-item>
											</el-col>
											<el-col :span="24" v-if="item.type == 1 || item.type == 4">
												<!-- 多行文本 -->
												<el-form-item v-if="item.type == 1" :label="item.title" prop="">
													<el-input type="textarea" rows="5" v-model="item.value" placeholder=""
														disabled></el-input>
													<i v-if="item.default_status != 1"  class="el-icon-delete" style="margin-left: 5px;" @click="delGroup(index,childIndex)"></i>
												</el-form-item>
												<el-form-item v-if="item.type == 4" :label="item.title" prop="">
													<el-upload disabled action="https://jsonplaceholder.typicode.com/posts/"
														list-type="picture-card">
														<i class="el-icon-plus"></i>
													</el-upload>
													<i v-if="item.default_status != 1" class="el-icon-delete" style="margin-left: 5px;" @click="delGroup(index,childIndex)"></i>
												</el-form-item>
											</el-col>	
										</div>
										<div v-if="form.type == 4" style="width: 100%;display: inline-block;">
											<div style="margin: 0 auto;height:35px;line-height: 35px;text-indent: 10px;font-weight: bold;font-size: 12px;">
												<div v-for="(item,index) in checked" style="border: 1px solid #E4E7ED;">{{item.title}}</div>
												<!-- <div style="border: 1px solid #E4E7ED;">资产分类</div>
												<div style="border: 1px solid #E4E7ED;">资产分类</div> -->
											</div>
										</div>
									</div>
									
									
								<!-- </div> -->
							</el-row>
							<div class="field-right">
								<span style="font-weight: bold;">属性设置</span>
								<div v-if="preview.field_name">
									<el-form-item label="标题" prop="">
										<el-input style="width: 220px;" v-model="preview.title" placeholder=""
											:disabled="preview.disabled"></el-input>
										<span style="color: #909399;font-size: 12px;"
											v-if="preview.default_status == 1">系统预置字段，不可修改</span>
									</el-form-item>

									<el-form-item label="必填">
										<el-switch v-model="preview.required_status" active-color="#13ce66"
											:disabled="preview.disabled">
										</el-switch>
									</el-form-item>
									<el-form-item label="数据来源" v-if="preview.type == 9 && !preview.disabled ">
										<el-button type="" size="small" @click="addTypeInput()"><i
												class="el-icon-plus"></i></el-button>
										<div v-for="(rItem,rIndex) in preview.content"
											style="display: ruby;margin-bottom:10px;">
											<label>选项{{rIndex+1}}:</label>
											<el-input v-model="rItem.name" placeholder=""
												style="width:200px;display:block;margin-left:20px;"></el-input>
											<el-button size="small" icon="el-icon-delete" @click="delInput(rIndex)">
											</el-button>
										</div>
									</el-form-item>
								</div>
								<!-- <div v-if="preview.isGroup">
									<el-form-item label="标题" prop="">
										<el-input style="width: 220px;"
											v-model="groupData[preview.groupIndex].group_name" placeholder="">
										</el-input>
									</el-form-item>
								</div> -->
								
								<div  v-if="form.type == 4">
								  <el-checkbox-group v-model="req_field_ids" >
									<el-checkbox @change="changeReqField"  v-for="item in form.req_list"  :label="item.id" :disabled="item.location_type==1?true:false">{{item.title}}</el-checkbox>
								  </el-checkbox-group>   
								</div>   
							</div> 
						</div>
					</el-tab-pane>
					<el-tab-pane label="流程设计" name="process">
						<el-form-item label="" prop="" class="process-box" style="width: 300px;margin: 0 auto;">
							<div class="item">
								<div class="title" style="background-color: #576a95;">发起人</div>
								<div class="appro-info">审批信息</div>
							</div>
							<div class="add-node-box">
								<div>
									<i class="el-icon-circle-plus-outline" @click="addNode"></i>
								</div>
							</div>
							<template v-for="(item,index) in processData">
								<div class="item" style="width: 200px;border-radius: 5px;">
									<div class="title">
										<span>审批人</span>
										<i class="el-icon-close" @click="delNode(index)"></i>
									</div>
									<div class="appro-info">
										<template v-if="!item.content">
											<span v-if="item.type == 1">发起人自己</span>
											<span v-if="item.type == 2">指定成员 审批 {{item.admin_name}}</span>
										</template>
										<template v-else>
											<span>{{item.content}}</span>
										</template>
										<i class="el-icon-arrow-right" @click="handleSelectAdmin(index)"></i>
									</div>
								</div>
								<div class="add-node-box">
									<div>
										<i class="el-icon-circle-plus-outline" @click="addNode"></i>
									</div>
									<span v-if="processData.length == index+1">结束</span>
								</div>
							</template>
						</el-form-item>
					</el-tab-pane>
					<!-- <el-tab-pane label="高级设置" name="advanced">
				高级设置
			</el-tab-pane> -->
				</el-tabs>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirm()">确 定</el-button>
			</div>
			<SelectAdminVue ref="adminDialog" :processData.sync="processData"></SelectAdminVue>
		</el-dialog> 

		<FieldVue ref='fieldDialog' :fieldData.sync="fieldData" v-if="Object.keys(fieldData).length > 0"></FieldVue>
		
	</div>
</template>

<script>
	import FieldVue from '../Card/AddField.vue'
	import SelectAdminVue from './SelectAdmin.vue'
	export default {
		inject: ["reload"],
		components: {
			FieldVue,
			SelectAdminVue
		},
		data() {
			return {
				processData: [
					{admin_id:'',content:'请选择审批信息',type:1}
				],
				checkboxGroup: ['receipt_number','user_id'],
				activeName: 'card',
				dialogAddVisible: false,
				groupData: [],
				fieldData: [],
				card_name: '',
				cate_id: [],

				preview: { //右侧预览
					groupIndex: 0,
					// isGroup: false,
					field_name: '',
					type: 0,
					field_keys: [],
					content: [{
						name: ''
					}, ],
					required_status: 0,
					disabled: false,
				},
				req_field_ids:[],
				form: {
					id: 0,
					name: '',
					cate_id: [],
					
				},
				defaultProps: {
					label: "name",
					children: 'children',
				},
				checked:[]
			}
		},
		watch:{
		},
		created() {

		},
		mounted() {

		},
		methods: {
			// handleEdit(id) {
			// 	this.handleEdit(id)
			// },
				
			handleSelectAdmin(index){
				this.$refs.adminDialog.getData(index,this.processData)
			},	
			//添加审批节点
			addNode() {
				this.processData.push({admin_id:'',content:'请选择审批信息',type:1})
			},
			//删除审批节点
			delNode(index){
				this.processData.splice(index, 1);
			},
			changeReqField(data,event){
				let checked = []
				this.form.req_list.forEach((item,index)=>{
					this.req_field_ids.forEach((item2)=>{
						if(item.id == item2){
							checked.push(item)
						}
					})
				})
				
				this.checked = checked
			},

			//左侧添加字段
			addField() {
				this.$refs.fieldDialog.showDialog()
			},
			delInput(rIndex) { //删除动态文本框
				this.preview.content.splice(rIndex, 1)
			},
			
			// 删除分组
			delGroup(index,childIndex=-1){
				if(childIndex < 0){
					this.groupData.splice(index, 1);
				}else{
					this.groupData[index]['data'].splice(childIndex, 1);
				}
			},

			//添加分组
			appendGroup() {
				console.log(this.groupData)
				let data = {
					data: [],
					group_name: '分组标题'
				}

				this.groupData.push(data)
			},

			//追击单选文本框
			addTypeInput() {
				let data = {
					name: ''
				}
				this.preview.content.push(data)
			},

			handleEdit(id) {
				this.$api.get('AssetForm/edit', {
					id: id
				}, res => {
					if (res.code == 200) {
						this.form = res.data.info
						this.fieldData = res.data.field_data
						this.groupData = res.data.group_data
						this.processData = res.data.process_data.length>0?res.data.process_data:this.processData
						this.dialogAddVisible = true
						
						if( res.data.info.req_field_ids){
							 res.data.info.req_field_ids.forEach((item)=>{
								 this.req_field_ids.push(parseInt(item))
							 })
						}
						// this.req_field_ids =  res.data.info.req_field_ids
						// console.log(this.req_field_ids) 
						this.$nextTick(() => {
							// this.$refs.tree.setCheckedNodes(res.data.info.checked_keys)
							this.checkboxGroup = res.data.default_checked
							console.log(this.checkboxGroup )
						})
					}
				})
			},

			confirm() {
				let url = 'AssetForm/edit'
				this.form.field_data = this.groupData
				this.form.process_data =  this.processData
				this.form.req_field_data =  this.checked
				this.$api.post(url, this.form, res => {
					if (res.code == 200) {
						this.dialogAddVisible = false
						this.$message.success(res.msg)
						this.reload();
						this.$refs["form"].resetFields();

					} else {
						this.$message.error(res.msg)
					}
				})
			},
			// 选中表单元素
			clickFormField(data, index = '') {
				// if (!data.field_name) { //选中分组	
				// 	this.preview = {
				// 		groupIndex: index,
				// 		isGroup: true
				// 	}
				// } else { //选中组元素
				// 	this.$nextTick(() => {
				// 		this.preview = data
				// 		if (data.content) {
				// 			this.preview.content = data.content
				// 		}
				// 		if (data.default_status == 1) this.preview.disabled = true
				// 		this.preview.required_status = data.required_status == 1 ? true : false
				// 		this.preview.isGroup = false
				// 	})
				// }
				this.$nextTick(() => {
					this.preview = data
					if (data.content) {
						this.preview.content = data.content
					}
					if (data.default_status == 1) this.preview.disabled = true
					this.preview.required_status = data.required_status == 1 ? true : false
					this.preview.isGroup = false
				})
			},

			//监听当前选中CheckBox
			handleField(data, event) {
				//  this.checkboxGroup.forEach(fItem=>{
				if (event) { //选中
					var status = true
					this.groupData.forEach((item) => {
						// item.data.forEach((iItem, iKey) => {
						// 	if (data.field_name == iItem.field_name) {
						// 		status = false
						// 	}
						// })
						if (data.field_name == item.field_name) {
							status = false
						}
					})
					if (status) {
						// data['group_key'] = this.groupData.length - 1
						this.groupData.push(data)
					}
				} else { //取消
					if (data.default_status == 1) {
						this.$message.error('系统预设字段不可取消！')
						return false
					}
					this.groupData.forEach((item, index) => {
						// item.data.forEach((iItem, iKey) => {
						// 	if (data.field_name == iItem.field_name) {
						// 		this.groupData[index]['data'].splice(iKey, 1)
						// 	}
						// })
						if (data.field_name == item.field_name) {
							this.groupData.splice(index, 1)
						}
					})
					this.checkboxGroup.forEach((item, index) => {
						if (data.field_name == item) {
							this.checkboxGroup.splice(index, 1)
						}
					})
				}
				console.log(this.checkboxGroup)
			},
		},
	}
</script>
<style lang="scss">
	.field-content {
		.el-input__inner {
			background: white !important;
		}

		.el-textarea__inner {
			background: white !important;
		}
	}

	.field-left {
		.field-list {
			.el-checkbox-group {
				.el-checkbox-button__inner {
					width: 120px;
					text-align: left;
				}
			}
		}
	}

</style>
<style scoped lang="scss">
	.card-box {
		display: flex;

		.field-left {
			width: 15%;
			padding-right: 15px;

			.el-button {
				width: 120px;
				text-align: left;
				margin-top: 5px;
			}

			.el-checkbox-button__inner {
				width: 150px;
			}
		}

		.field-content {
			padding-left: 15px;
			width: 60%;
			height: 100vh;
			border-left: 1px solid #DCDFE6;
			border-right: 1px solid #DCDFE6;
			overflow: auto;
		}

		.field-right {
			padding-left: 15px;
			width: 10%;
		}
	}

	.process-box {
		.item {
			width: 200px;
			border-radius: 10px;
			box-shadow: 3px 3px 3px 2px grey;

			margin: 0 auto;
			margin-bottom: 10px;

			.title {
				position: relative;
				font-size: 12px;
				border-radius: 5px 5px 0px 0px;
				width: 100%;
				height: 30px;
				background: #ff943e;
				color: white;
				line-height: 30px;
				font-weight: bold;
				text-indent: 20px;

				.el-icon-close {
					color: white;
					font-size: 18px;
					position: absolute;
					right: 5px;
					top: 5px;
				}
			}

			.appro-info {
				font-size: 12px;
				text-indent: 20px;
				min-height: 50px;
				position: relative;
				.el-icon-arrow-right{
					position: absolute;
					right: 5px;
					top: 10px;
					font-size: 18px;
				}
			}
		}

		.add-node-box {
			width: 200px;
			margin: 0 auto;
			margin-bottom: 10px;
			text-align: center;

			div {
				width: 1px;
				height: 100px;
				background: #b3b3b3;
				margin: 0 auto;
				position: relative;

				i {
					color: #409EFF;
					font-size: 30px;
					position: absolute;
					top: 55%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: white;
				}
			}
		}
	}
</style>
