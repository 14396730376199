<template>
<div>
	<el-dialog
	  title="提示"
	  :visible.sync="dialogVisible"
	  width="45%"
	  >
	 <el-form  label-position="top"    ref="form" :model="form" prop=""  >
		
		<!-- <div v-for="(item,index) in form.items" style="border:1px dashed;padding:20px;"> -->
			<!-- <el-form-item v-if="item.type !== 10"   label="选择分组" prop="label">
				<el-select v-model="form.group_key">
					<el-option v-for="(item,index) in group" :value="index" :label="item.group_name"></el-option>
				</el-select>
			 </el-form-item> -->
			<el-form-item label="内容类型" prop="">
			    <el-radio-group class="type-radio" v-model="form.type">
			        <el-radio  :label="0">单行文本</el-radio>
			        <el-radio  :label="1">描述说明</el-radio>
			        <el-radio  :label="3">复选框</el-radio>
			        <el-radio  :label="4">图片</el-radio>
			        <el-radio  :label="6">日期</el-radio>
			        <el-radio  :label="7">时间</el-radio>
					<el-radio  :label="9">下拉</el-radio>
					<el-radio  :label="10">附件</el-radio>
			    </el-radio-group>
			</el-form-item>

			<el-form-item v-if="form.type !== 1"   label="标题" prop="label">
				<el-input  v-model="form.title" style="width: 220px;"></el-input>
			 </el-form-item>
			 <el-form-item v-if="form.type == 1"   label="标题" prop="label">
			 	 <el-input type="textarea" rows="5" v-model="form.title" style="width: 220px;"></el-input>
			  </el-form-item>
		    <!-- 单选 -->
		    <el-form-item  v-if="form.type == 2 || form.type == 3 || form.type == 9"  :label="'选项'+rIndex" prop="">
		        <el-button type="" size="small" @click="addTypeInput()"><i class="el-icon-plus"></i></el-button>
		        <div  v-for="(rItem,rIndex) in form.content" style="display:flex;margin-bottom:10px;">
		            <label>选项{{rIndex+1}}:</label>
		            <el-input v-model="rItem.name" placeholder=""  style="width:200px;display:block;margin-left:20px;"></el-input>
		            <el-button size="small" icon="el-icon-delete" @click="delInput(rIndex)"></el-button> 
		        </div>
		    </el-form-item>
			</el-form-item>
			<el-form-item label="必填">
				<el-switch
				  v-model="form.required_status"
				 >
				</el-switch>
			</el-form-item>
			
		<!-- </div> -->
	 </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible = false">取 消</el-button>
	    <el-button type="primary" @click="confirm">确 定</el-button>
	  </span>
	</el-dialog>
</div>
</template>

<script>
export default {
	props:['fieldData'],
    data() {
        return {
			dialogVisible:false,
			rIndex:1,
			form:{
				group_key:'',
			    title:'',
			    content:'',
				required_status:0,
				type:0,
				content:[{
					name:''
				}]
			},
			group:this.fieldData,
			field_name:''
        }
    },
    created() {
    },
    mounted() {

    },
	
    methods:{
		showDialog(){
			
			this.dialogVisible =  true
		},
		confirm(){
			console.log(this.group)
			if(this.form.type !== 2 && this.form.type !== 3 && this.form.type !== 9) this.form.content  = []
			this.$api.get('AssetCard/createFieldName', {title:this.form.title}, res => {
				if (res.code == 200) {
					this.field_name =  res.data.field_name
					this.$nextTick(()=>{
						var temp  = {
								title:this.form.title,		
								type:this.form.type,
								content:this.form.content,
								required_status:this.form.required_status,
								field_name:this.field_name
							}
						this.group.push(temp)
						this.$emit('update:fieldData',this.group)
						this.dialogVisible =  false
					})
				
				}
			})

			
		},
		addTypeInput(){    //追击单选文本框
		    let data = {
		        name:''
		    }
			this.form.content.push(data)
		    // this.form.items[index]['content'].push(data)
		},
		
		delInput(rIndex){ //删除动态文本框
		    this.form.content.splice(rIndex,1)
		},

    },
}
</script>
<style scoped lang="scss">
	.type-radio{
		.el-radio{
			margin-right: 20px;
		}
	}
</style>